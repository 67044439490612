const settings = {
  stage:  'production',
  frontEndAPIBaseUrl: 'https://sucschools.com/system/api', // dev: http://localhost/api
  keycloak: {
    realm: 'start-up-community',
    url: 'https://sucschools.com/auth', // dev: http://localhost:8080/auth
    clientId: 'suc-front-end',
  },
  realmRoles: {
    manageFinanceReport: "manage_finance_report",
    manageLanguage: "manage_language",
    financeManager: "administration_and_finance_manager",
    adminReceptionist: "admin_cashier_receptionist",
    adminSupervisor: "admin_supervisor",
    branchManager: "branch_manager",
    assistantBrandManager: "assistant_brand_manager",
    owner: "owner"
  }
};

export default settings;
